@import './variable.scss';

@supports (bottom: constant(safe-area-inset-bottom)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left)
  }
}

@supports (bottom: env(safe-area-inset-bottom)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left)
  }
}

.App {
  // text-align: center;
  // background-color: #181d26;
  // padding-bottom: calc(px(50) + var(--safe-area-inset-bottom));
  max-width: 1920px;
  margin: 0 auto;
}

button {
  border: 1px solid transparent;
}

::-webkit-scrollbar {
  display: none;
}

.app-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  // gap: px(40);
  // padding: 0 px(30) 0 px(30);
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  position: fixed;
  // height: px(60);
  height: calc(px(50) + var(--safe-area-inset-bottom));
  background-color: #181d26;
  box-shadow: 0 -1px 0 0 rgba(255, 255, 255, 0.04);

  .app-footer-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #7a8088;
    font-size: px(12);
    flex: 1;

    &.active {
      .text {
        color: #e8ba41;
      }
    }
  }

  .text {
    font-size: px(12);

    font-weight: 600;
  }
}

// 媒体查询适配
@media screen and (min-width: 960px) {
  .app-footer {
      display: none;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

html,
body {
  // min-height: 100%;
}

#root,
.App {
  // min-height: 100%;
  background-color: #181d26;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.light-purple {
  background-color: #6faada;
  border-radius: px(6);

  &:disabled {
    color: rgba($color: #fff, $alpha: 0.3) !important;
    background-color: rgba($color: #6faada, $alpha: 0.3) !important;
  }
}

.teal-green {
  background-color: #2ebd85;
  border-radius: px(6);

  &:disabled {
    color: rgba($color: #fff, $alpha: 0.3) !important;
    background-color: rgba($color: #2ebd85, $alpha: 0.3) !important;
  }
}

.rc-input {
  color: #fff;
}

.google-login{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #4285f4;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}
