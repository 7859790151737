@import 'src/variable.scss';

.rug-chose-currency {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;

    &.visible {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .modal-mask {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.8);
    }

    .modal-content {
        position: relative;
        overflow-y: scroll;
        overflow-x: hidden;
        /* 隐藏滚动条 */
        scrollbar-width: none;
        /* Firefox 中隐藏滚动条 */
        width: px(280);
        padding: px(10) px(10) px(14) px(19);
        border-radius: px(5);
        background-color: #29313d;


        .svg-icon {
            position: absolute;
            top: px(10);
            right: px(10);
            line-height: 0;
        }

        .title {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            height: px(18);
            font-size: px(16);
            margin-top: px(12);
            color: #7a8088;
        }

        .currency-list {
            gap: px(4);
            margin-top: px(14);
            max-height: 60vh;
            overflow-y: auto;

            .item {
                display: flex;
                align-items: center;
                width: px(242);
                height: px(34);
                flex-shrink: 0;
                padding: 0 px(10);
                border-radius: px(6);
                font-size: px(14);
                font-weight: Semibold;
                letter-spacing: 0.22px;
                color: #cbced2;

                .svg-radio {
                    width: px(12);
                    height: px(12);
                    flex-shrink: 0;
                }

                .icon {
                    width: px(24);
                    height: px(24);
                    margin-left: px(17);
                    flex-shrink: 0;
                }

                .name {
                    margin-left: px(8);
                    margin-right: px(8);
                }

                .coin-tag {
                    padding: 1px 6px;
                    border-radius: 4px;
                    border: solid 1px #9d9d9d;
                    background-color: #202630;
                    font-size: px(8);
                    margin-left: auto;
                }

                &.active {
                    background-color: #202630;
                }
            }
        }

        .confirm-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: px(242);
            height: px(40);
            border-radius: px(6);
            background-color: #e8ba41;
            font-weight: 'Semibold';
            font-size: px(17);
            color: #000;
            margin-top: px(25);
        }
    }
}