@import 'src/variable.scss';

.sign-modal {
    
    .game-play-content {
        padding: px(20) px(20) px(20) px(20);
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #fff;
        font-size: 0.8rem;
        gap: px(10);
    }

    .ai-modal-title {
        padding-left: px(16);
        height: px(48);
        font-weight: 600;

        .title-text {
            color: #e8ba41;
            font-size: 1rem !important;
        }
       
    }

    .game-play-img {
        margin-bottom: px(20);
    }

    .game-play-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #cbced2;
    }

    .game-play-bottom{
        font-size: 1rem;
        height: 2rem;
        align-items: center;
        display: flex;
        justify-content: center;
        background-color: #202630;
        color: #cbced2;
        cursor: pointer;
        text-decoration: underline;
    }
}