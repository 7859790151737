$colors: #a2b9bc,#6b5b95,#feb236, #d64161, #ff7b25, #b2ad7f,  #878f99;

.avatar1 {
    color: #fff; 
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:100%;
    width:50px;
    height: 50px;
    font-weight: bold;
    flex-shrink: 0;
    background-color: #a2b9bc;
    font-size: 0.8rem;
}