@import 'src/variable.scss';

.insBalance-modal {
    .ai-modal-content-wrap {
        width: px(300);
    }

    .ai-modal-title {
        padding-left: px(18);
        font-size: px(17);
        font-weight: 600;

        .title-text {
            color: #e8ba41;
        }
    }

    .insBalance-modal-content {
        padding: px(16.5) px(17) px(18) px(17);

        .text {
            height: px(68);
            font-size: px(11);
            color: #cbced2;
        }

        .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: px(266);
            height: px(40);
            border-radius: px(6);
            color: #000;
            font-size: px(17);
            font-weight: 600;
            background-color: #e8ba41;
        }
    }
}