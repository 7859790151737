@import './variable.scss';

html {
  min-height: 100%;
  background-color: #181d26;
}

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  // // font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  //   monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: NotoSansMono_SemiCondensed;
}

ul li {
  list-style: none;
}

.highcharts-credits {
  display: none;
}

.rc-switch {
  width: px(40);
  height: px(20);
  border-radius: px(19);
  background-color: #2a313c;
  border-color: #2a313c;

  &::after {
      width: px(15);
      height: px(16);
      background-color: rgba(122, 128, 136, 0.43);
      ;
  }

  &:focus {
      box-shadow: none;
  }

  &.rc-switch-checked {
      border: 1px solid #2a313c;

      &::after {
          left: px(22);
          background-color: #e8ba41;
      }
  }
}

.date-select {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // margin-top: px(10);
  margin-bottom: px(5);

  .date-select-item {
      display: flex;
      align-items: center;
      height: px(21);
      padding: 0 px(6);
      font-size: px(12);
      color: #7a8088;
      border-radius: px(5);

      &.active {
          background-color: #2a313c;
      }
  }
}

.rc-notification {
  height: auto;

  &.rc-notification-top {
    align-items: center;
    width: 100%;
  }

  .rc-notification-notice-wrapper {
    width: px(340);
  }

  .rc-notification-notice-content {
    padding: 0;
  }

  .rc-notification-notice-wrapper {
    border-radius: 0;
  }

  .error-content,
  .success-content {
    display: flex;
    align-items: center;
    padding: px(5) px(9) px(5) px(23);
    color: #fff;
    background-image: linear-gradient(to bottom, #3baf8f, #2ca475);

    .svg-icon {
      position: absolute;
      width: px(20);
      height: px(20);
      right: px(9);
      display: flex;
      align-items: center;
    }

    .wins-wrap {
      position: absolute;
      right: px(60);
    }

    .success-content-text {
      display: inline-block;
      text-align: center;
      max-width: px(150);
      overflow: hidden;

      .title {
        font-size: px(12);
        font-weight: 600;
        line-height: px(17);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .wins-wrap {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: px(92);
      height: px(40);
      color: #fff;
      background-color: rgba(#202630, 0.3);
      border-radius: px(21);
      padding-top: px(2);

      .title {
        font-size: px(12);
        line-height: px(17);
        font-weight: 600;
        text-align: center;
      }

      .multiple {
        font-size: px(16);
        font-weight: 600;
        text-align: center;
        line-height: px(22);
      }
    }
  }

  .reconnect-btn {
    width: px(30);
    height: px(20);
    line-height: px(20);
    border-radius: px(3);
    background-color: #2ebd85;
    text-align: center;
    font-size: px(12);
    margin-left: px(8);
    margin-right: px(8);
  }

  .error-content {
    background-image: linear-gradient(to bottom, #c12a21, #a42c2c);
  }
}


// 确认弹框
.ai-modal-confirm {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .ai-modal-mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
  }

  .svg-icon {
    position: absolute;
    top: px(8);
    right: px(7);
    svg path {
      fill: #7a8088;
      opacity: 1;
    }
  }

  .ai-modal-content {
    position: relative;
    width: px(300);
    // height: px(206);
    padding: px(8) px(17) px(18) px(17);
    border-radius: px(16);
    background-color: #2a313c;
    z-index: 1;

    .title {
      display: flex;
      height: px(26);
      width: 100%;
    }

    .text {
      font-size: px(15);
      color: #7a8088;
      word-break: break-all;
    }

    .btn-groups {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: px(20);
      margin-top: px(15);

      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: px(123);
        height: px(40);
        border: 1px solid;
        font-size: px(15);
        border-radius: px(6);

        &.cancel-btn {
          border-color: #7a8088;
          background-color: #202630;
          color: #7a8088;
        }

        &.confirm-btn {
          border-color: #e8ba41;
          background-color: #e8ba41;
          color: #000;
        }
      }
    }
  }
}

.usdt-select-label-wrap {
  display: flex;
  align-items: center;
  gap: px(11);
  color: #cbced2;
  font-size: px(14);

  .usdt-icon {
    width: px(23);
    height: px(23);
  }
}

.green-usdt {
  
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
input[type="number"] {
  -webkit-appearance: none; /* Chrome 和 Safari */
  -moz-appearance: textfield; /* Firefox */
}