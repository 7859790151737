@import 'src/variable.scss';

.app-page-landing {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #202630;
    z-index: 10;

    .see-result-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 7%;
        left: 50%;
        transform: translateX(-50%);
        width: px(186);
        height: px(36);
        border-radius: px(6);
        background-color: #e8ba41;
        font-size: px(15);
        font-weight: 600;
        z-index: 1;
        color: #000;

        .svg-icon {
            margin-left: px(10);
        }
    }


    .coin {
        position: absolute;
        top: 43%;
        left: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        // width: px(211);
        // height: px(211);
        width: px(191);
        height: px(191);
        background: url('../../images/coin-shadow.png') no-repeat;
        background-size: contain;
        transform: translateX(-50%);

        .coin-img {
            width: px(141);
            height: px(141);
            background: url('../../images/coin.png') no-repeat;
            background-size: contain;
        }

        .usdt-img {
            width: px(141);
            height: px(141);
            background: url('../../images/t.png') no-repeat;
            background-size: contain;
        }
    }

    .page-landing-logo {
        position: absolute;
        top: 12%;
        left: 50%;
        width: 4.75rem;
        height: 5.2rem;
        background: url('../../images/logo-3@2x.png') no-repeat;
        transform: translateX(-50%);
        background-size: contain;
    }

    .analysis-step {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 41%;
        left: 50%;
        transform: translateX(-50%);
        gap: px(11);

        &.hidden {
            display: none;
        }

        .analysis-step-item {
            display: flex;
            align-items: center;
            width: px(300);
            height: px(30);
            padding: px(2.5) 0 px(1.3) px(8.8);
            border-radius: px(23.8);
            background-color: #2a313c;

            .text {
                margin-left: px(8.8);
                font-size: px(14);
                color: #cbced2;
            }

        }
    }

    &.app-page-landing-visible {
        display: block;
    }

    .amazing-text {
        position: absolute;
        color: #e8ba41;
        font-size: px(24);
        line-height: px(28);
        width: 100%;
        top: 35%;
        text-align: center;

        .img-premium {
            position: relative;
            top: px(4);
            left: px(2);
            display: inline-block;
            width: 1.4rem;
            height: 1.4rem;
            background-image: url('../../images/star.png');
            background-size: cover;
        }
    }

    .thanks-text {
        position: absolute;
        width: 100%;
        text-align: center;
        font-size: px(13);
        font-weight: bold;
        color: #7a8088;
        bottom: 18%;
    }

    .coin-number-text {
        position: absolute;
        width: 100%;
        text-align: center;
        font-size: px(24);
        font-weight: bold;
        color: #e8ba41;
        bottom: -10%;
    }
}