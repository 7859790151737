@import 'src/variable.scss';

.share-content-drawer {
    width: 100%;
    height: px(420);
    padding: px(30) px(15) 0 px(15);
    background-color: #202630;

    .btn {
        width: 100%;
        height: px(40);
        border-color: transparent;
        border-radius: px(6);
        font-size: px(17);
        color: #fff;
        font-weight: 600;

        &.play {
            background-color: #2ebd85;
        }

        &.invite {
            background-color: #6faada;
        }

        &.social {
            background-color: #d1a73b;
        }
    }

    .No-withdraw-limitma {
        display: flex;
        font-size: px(13);
        color: #7a8088;
        line-height: px(18);
        margin: px(5) 0 px(15) 0;

        .text-style-1 {
            color: #e8ba41;
        }
    }

    .invite-btn-tips {
        margin-bottom: px(24);
    }

    .share-link-content {
        position: relative;
        width: px(345);
        height: px(92);
        margin-top: px(46);
        border-radius: px(10);
        background-color: rgba($color: #000000, $alpha: 0.2);

    }

    .share-top-icon {
        position: absolute;
        left: 50%;
        top: px(-38);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        transform: translateX(-50%);

        .text {
            // width: 100%;
            margin-top: px(45);
            font-size: px(13);
            color: #7a8088;
            line-height: px(18);
        }
    }

    .share-btn-group {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: px(22);
        padding-top: px(45);
    }

    .copy-sucess {
        display: flex;
        align-items: center;
        color: #fff;
    }


    .deposit-success-content {
        display: flex;
        align-items: center;
        height: px(50);
        padding: px(0) px(18);

        .status-text,
        .success-content-text {
            font-size: px(15);
        }

        .wins-content {
            display: flex;
            align-items: center;
            height: px(26);
            border-radius: px(21);
            padding: 0 px(11) 0 px(4);
            font-size: px(13);
            background-color: rgba(#202630, 0.3);
            margin-left: px(18);
            margin-right: px(11);

            .USDT {
                width: px(19);
                height: px(19);
                margin-right: px(9);
            }
        }

    }

    
}

.share-btn-group {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: px(22);
    padding-top: 20px;
}

// 媒体查询适配
@media screen and (min-width: 960px) {
    .share-content-drawer {
        display: none;
    }
}