@import 'src/variable.scss';

.how-to-earn-modal {

    .how-to-earn-modal-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: px(14) px(15);

        >* {}
    }

    .ai-modal-title {
        padding-left: px(16);
        font-size: px(17);
        height: px(48);
        font-weight: 600;

        .title-text {
            color: #e8ba41;
        }
    }

    .uid-block {
        font-size: px(18);
        line-height: px(35);
        display: flex;
        align-items: center;
        width: 100%;
        height: px(35);
        padding-left: px(10);
        padding-right: px(10);
        font-weight: bold;
        border-radius: px(7);
        background-color: rgba($color: #000000, $alpha: 0.2);
        margin-bottom: px(20);
        color:#cbced2;

        .copy-btn {
            margin-left: auto;
        }
    }

    .text-1 {
        color: #85889e;
        font-size: px(13);
        line-height: px(18);
        //text-align: justify;

        p {
            //text-align: justify;
        }
    }

    .text-2 {
        color: #e25461;
        font-size: px(12);
        line-height: px(17);
        margin-top: px(5);
    }

    .text-3-title {
        color: #cbced2;
        font-size: px(20);
        width: 100%;
        display: flex;
        justify-content: left;
    }

    .text-3 {
        margin-top: px(14);
    }

    .step-title {
        display: flex;
        align-items: center;
        width: 100%;
        height: px(26);
        padding-left: px(10);
        color: #e8ba41;
        font-size: px(13);
        font-weight: bold;
        border-radius: px(7);
        background-color: rgba($color: #000000, $alpha: 0.2);
    }

    .text-4 {
        font-size: px(12);
        color: #cbced2;
        margin-top: px(13);
    }

    .screen-shoot-1 {
        margin-top: px(9);
    }

    .text-5 {
        margin-top: px(25);
    }

    .text-6 {
        font-size: px(12);
        color: #cbced2;
        line-height: px(18);
        margin-top: px(11);
    }

    .screen-shoot-2 {
        margin-top: px(15);
    }

    .text-7 {
        margin-top: px(25);
    }

    .text-8 {
        font-size: px(12);
        line-height: px(18);
        color: #cbced2;
        //text-align: justify;
        margin-top: px(11);
    }

   

    .screen-shoot-3 {
        width: px(298);
        height: px(133);
        margin-top: px(14);
        border-radius: px(7);
        border: solid 1px rgba(151, 151, 151, 0.34);
        background-color: #202630;
        overflow: hidden;

        .screen-shoot-3-img {
            width: 100%;
            height: 100%;
            background: url('../../../../images/step3.png') no-repeat;
            background-size: cover;
        }
    }
    .text-more{
        font-size: px(12);
        color: #cbced2;
        margin-top: px(11);
    }

    .text-game {
        display: flex;
        flex-direction: column;

        .text-game-title{
            font-size: px(20);
            color: #cbced2;
            margin-top: px(11);
        }
    
        .text-game-1-title{
            font-size: px(16);
            color: #cbced2;
            margin-top: px(11);
        }
    
        .text-game-txt{
            font-size: px(12);
            color: #cbced2;
            margin-top: px(11);
            display: flex;
            flex-direction: column;
            span {
                display: flex;
            }
        }
    }

    
}