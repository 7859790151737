@import 'src/variable.scss';

.sign-modal {
    
    .sign-modal-content {
        padding: px(53) 0 px(53) 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .ai-modal-title {
        padding-left: px(16);
        height: px(48);
        font-weight: 600;

        .title-text {
            color: #e8ba41;
            font-size: 1rem !important;
        }
       
    }
    
    .sign-btn {
        display: flex;
        align-items: center;
        width: 15rem;
        height: 2rem;
        border-radius: 8px;
        background-color: #fff;
        font-size: 1rem;
        justify-content: space-around;
        cursor: pointer;
        padding: 0 1rem;
    }
    
    .sign-btn-item {
        display: flex;
    }
    
}