@import 'src/variable.scss';

.rug-pull {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // background-color: #181d26;
    z-index: 2;
    // padding-top: px(56);
    color: #cbced2;
    font-size: px(19);
   
    .text {
        margin-bottom: px(13);
    }

    .current-pull-number {
        padding: px(9) px(21) px(6) px(15);
        border-radius: px(8);
        background-color: rgba($color: #000000, $alpha: 0.2);
    }

    &.active {
        display: flex;
    }
}