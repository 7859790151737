@import 'src/variable.scss';


.rug-header {
    position: fixed;
    &.active-rug {
        position: absolute; 
        z-index: 9;
        padding: 0 px(10);
        background-color: rgba(255,255,255,0);
        width: percentage($number: 1)
    }
    top: 0;
    left: 0;
    width: 100%;
    padding: px(4) 0 0 0;
    background-color: #202630;
    z-index: 10;

    .header-top-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: px(8);
        padding: 0 px(15) 0 px(15);

        .header-top-content-right {
            display: flex;
            align-items: center;
        }

        .back-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            height: px(28);
            margin-right: px(10);
            width: px(45);
            margin-left: px(-15);

            .back-icon {
                
            }
        }

        .name {
            font-size: px(15);
            letter-spacing: 0.5px;
            color: #cbced2;
            margin-left: px(9);
        }

        .select {
            display: flex;
            align-items: center;
            width: px(119);
            height: px(28);
            padding: px(5) px(10) px(4) px(7);
            border-radius: px(5);
            border: solid 1px #323943;
            background-color: #29313d;

            .icon {
                width: px(19);
                height: px(19);
                margin-right: px(9);
            }

            .select-label {
                font-size: px(12);
                color: #27a17c;
                flex: 1;
                font-weight: bold;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .drop-down-icon {
                display: flex;
                align-items: center;
                line-height: 0;
                height: px(5);
            }
        }

        .language-btn {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-left: px(10);
            width: px(45);
            height: px(28);
            border: 1px solid #323943;
            background-color: #29313d;
            color: #85889E;
            font-size: px(12);
            border-radius: px(5);
        }
    }

    .logo-wrap {
        display: flex;
        align-items: center;

        .help {
            width: px(17);
            height: px(17);
            margin-left: px(6);
        }
    }

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: px(103);
        height: px(32);
        // border-radius: px(8);
        // background-color: #29313d;
        background: url('../../images/logo.svg');
        background-repeat: no-repeat;
        background-size: contain;
    }

    .header-bottom-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: #1e232c;

        .wrap {
            display: flex;
            align-items: center;
            width: 100%;
            height: px(28);
            padding: 0 px(15) 0 px(15);
            justify-content: space-between;
        }

        .multiple {
            display: flex;
            overflow-x: hidden;
            flex: 1;
        }

        .multiple-item {
            font-size: px(12);
            padding-right: px(11);
        }

        .divider {
            width: px(1);
            height: px(12);
            opacity: 0.5;
            border: solid 1px #cbced2;
        }

        .round-history {
            display: flex;
            font-size: px(12);
            color: #cbced2;
        }

        .select-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: px(39);
            height: px(16);
            margin-left: px(15);
            padding: px(3) px(5) px(2) px(4);
            border-radius: 8px;
            border: solid 1px #323943;
            background-color: #29313d;

            &.open {
                .icon-three-arrow {
                    transform: rotate(180deg);
                }
            }

            .clock {
                width: px(12);
                height: px(11);
            }

            .icon-three-arrow {
                width: px(5);
                height: px(3);
            }
        }

        .select-dropdown-list {
            display: none;
            flex-wrap: wrap;
            width: 100%;
            gap: 8px;
            margin-top: px(6);
            padding: 0 px(15) px(15) px(15);

            &.open {
                display: flex;
            }
        }

        .select-dropdown-list-item-td {
            display: flex;
            align-items: center;
            height: px(22);
            background-color: #000;
            border-radius: px(11);
            font-size: px(11);
            padding: px(0) px(8.5);
        }

        // .select-dropdown-list-item {
        //     display: flex;
        //     width: 100%;
        //     align-items: center;
        //     gap: px(16);

        //     .select-dropdown-list-item-td {
        //         display: flex;
        //         align-items: center;
        //         height: px(22);
        //         background-color: #000;
        //         border-radius: px(11);
        //         font-size: px(11);
        //         padding: px(0) px(8.5);
        //     }
        // }
    }
}


.rug-trend {
    position: relative;
    width: 100%;
    height: px(195);
    // height: calc(100vh - px(447));
    height: calc(100vh - px(440));
    margin-top: px(50);

    .app-chart {
        width: 100%;
        // height: px(195);
        height: 100%;
    }

    .rug-waiting-next-round {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #181d26;
        z-index: 2;

        &.visible {
            display: flex;
        }

        .trend {
            width: px(60);
            height: px(60);
        }

        .text {
            font-size: px(12);
            color: #7a8088;
            letter-spacing: 0.4px;
            margin-top: px(14);
            margin-bottom: px(19);
            line-height: px(17);
        }

        .progress-bar {
            width: px(170);
            height: px(7);
            border-radius: px(4);
            background-color: #202630;

            .progress-bar-content {
                width: 100%;
                height: px(5);
                border-radius: px(4);
                background-color: #e8ba41;
            }
        }

        .progress-num {
            margin-top: px(7);
            font-size: px(12);
            line-height: px(17);
            letter-spacing: 0.4px;

            .current {
                color: #e8ba41;
            }

            .total {
                color: #7a8088;
            }
        }
    }

    .how-to-eart-btn {
        position: absolute;
        display: flex;
        align-items: center;
        bottom: px(11);
        left: px(11);
        z-index: 5;
        width: auto;
        height: px(24);
        padding: 0 px(5);
        border-radius: px(12);
        background-color: rgba($color: #202630, $alpha: 0.6);
        cursor: pointer;

        .text {
            font-size: px(13);
            font-weight: bold;
            color: #7a8088;
            margin-left: px(4.5);
        }
    }
}

.menu-container {
    display: none;
}

.Sign{
    color: #7a8088;
    cursor: pointer;
    font-size: 0.8rem;
}

.Photo {
    width: 1.5rem;
    margin-right: 0.5rem;
}

@media screen and (max-width: 960px)  {
    .rug-header {
        &.active-rug {
            position: absolute; 
            z-index: 9;
            background-color: rgba(255,255,255,0);
            width: percentage($number: 1);
            padding: 0;
            top: px(-31);
        }
    }
}

@media screen  {
    .rug-trend {
        height: calc(100vh - 440px);
        margin-top: 3.6rem;
    }

    .header-top-content-right {
        .menu-container {
            position: relative;
            display: flex;
        }
        .menu-icon {
            margin-left: 1.2rem;
            // margin-right: 0.5rem;
            cursor: pointer;
        }
       
        .menu-list {
            position: absolute;
            width: 9.5rem;
            // height: 280px;
            top: 40px;
            right: 0;
            background-color: #202630;
            z-index: 2;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
            display: none;

            &.open {
                display: block;
            }

            .menu-list-item {
                display: flex;
                align-items: center;
                height: 2.5rem;
                padding-left: 0.8rem;
                gap: 0.8rem;
                color: #7a8088;
                font-size: 0.7rem;
                cursor: pointer;

                &.active {
                    background-color: #181d26;
                }
            }

            .line {
                margin: 0 0.8rem;
                height: 1px;
                background-color: #e8ba41;
            }
        }
    }
}