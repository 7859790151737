@import 'src/variable.scss';

.ai-modal {
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &.open {
        display: flex;
    }

    .ai-modal-content-wrap {
        position: relative;
        width: px(340);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: px(8);
        z-index: 1;
        overflow: hidden;
    }

    .ai-modal-mask {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
    }

    .ai-modal-title {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: px(48);
        padding: 0 9px;
        z-index: 1;
        background-color: #202630;

        .title-text {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #85889e;
            font-size: px(15);
        }

        .close-btn {
            line-height: 0;
            svg path {
                fill: #7a8088;
                opacity: 1;
            }
        }
    }

    .ai-modal-content {
        position: relative;
        width: 100%;
        background-color: #2a313c;
        z-index: 1;
        flex: 1;
        overflow: auto;
    }
}