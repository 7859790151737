@import 'src/variable.scss';

.charge-modal {
    .close-btn {
        position: absolute;
        top: px(10);
        right: px(10);
        line-height: 0;
        svg path {
            fill: #7a8088;
            opacity: 1;
        }
    }

    .ai-modal-title {
        display: none;
    }

    .charge-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: px(0) px(15) px(20) px(15);
        gap: px(25);
        
        .text {
            margin-top: px(-45);
            display: flex;
            // height: 50px;
            
            font-size: px(20);
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #e8ba41;
        }

        .text1{
            // width: 536px;
            // height: 26px;
            font-size: px(13);
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #fff;
        }
        .bottom{
            // display:  flex;
            align-items: center;
        }

        .go-btn{
            display: flex;
            justify-content: center;
            align-items: center;
            // width: 200px;
            padding: px(10) px(20);
            // height: 45px;
            border-radius: px(7.6);
            background-color: #e8ba41;
        }

        .customer-service {
            margin-top: px(10);
            background-color: #00172f !important;
            .support-text {
                color: #fff;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                font-weight: normal;
                font-size: px(22);
            }
        }
    }

    

    .icon {
        margin-right: 8px;

        &.charge {
            width: px(400);
            height: px(225);
            background: url('../../../../images/charge.png') no-repeat center;
            background-size: contain;
        }

        &.support {
            width: px(30);
            height: px(30);
            background: url('../../../../images/custom-service.png') no-repeat center;
            background-size: contain;
        }
    }
}