@import 'src/variable.scss';

.replayer-page {
    padding-bottom: calc(1.5rem + var(--safe-area-inset-bottom));

    

    .share-link-content {
        position: relative;
        left: 50%;
        margin-top: px(46);
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: px(345);
        height: px(92);
        border-radius: px(10);
        background-color: rgba($color: #000000, $alpha: 0.2);
    }

    .share-top-icon {
        position: absolute;
        left: 50%;
        top: px(-38);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        transform: translateX(-50%);

        .text {
            // width: 100%;
            margin-top: px(45);
            font-size: px(13);
            color: #7a8088;
            line-height: px(18);
        }
    }

    .rug-trend {
        // height: px(250);
        background-color: #000;

        .replayer-video-icon {
            position: absolute;
            top: px(10);
            left: px(10);
            z-index: 9999;
        }
    }

    .replayer-icon-content {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .text {
            font-size: px(17);
            color: #e8ba41;
            margin-top: px(17);
        }
    }
}

.replayer-page-header-bottom-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: px(28);
    padding: 0 px(11) 0 px(9.7);
    background-color: #1e232c;

    .round-info {
        color: #85889e;
        font-size: px(11);
    }

    .rate {
        color: #34b4ff;
        font-size: px(13);
        font-weight: 600;
    }
}

.replayer-page-toast1{

    .success-content {
        display: flex;
        align-items: center;
        padding: px(5) px(9) px(5) px(23);
        color: #fff;
        background-image: linear-gradient(to bottom, #3baf8f, #2ca475);
        min-width: px(340);
    
        .svg-icon {
          position: absolute;
          width: px(20);
          height: px(20);
          right: px(9);
          display: flex;
          align-items: center;
        }
    
        .wins-wrap {
          position: absolute;
          right: px(60);
        }
    
        .success-content-text {
          display: inline-block;
          text-align: center;
          max-width: px(150);
          overflow: hidden;
    
          .title {
            font-size: px(12);
            font-weight: 600;
            line-height: px(17);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
    
        .wins-wrap {
          display: flex;
          justify-content: center;
          flex-direction: column;
          width: px(92);
          height: px(40);
          color: #fff;
          background-color: rgba(#202630, 0.3);
          border-radius: px(21);
          padding-top: px(2);
    
          .title {
            font-size: px(12);
            line-height: px(17);
            font-weight: 600;
            text-align: center;
          }
    
          .multiple {
            font-size: px(16);
            font-weight: 600;
            text-align: center;
            line-height: px(22);
          }
        }
      }
}

.replayer-page-toast {
    position: absolute;
    left: 50%;
    width: 100%;
    bottom: 0;
    transform: translateX(-50%);
    width: auto;

    .rc-notification-notice-wrapper {
        width: auto;
        margin-bottom: 0;
        background-color: transparent;
    }

    .replayer-page-toast-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 4px;
        width: px(123);
        // height: px(69);
        padding: px(4) 0 px(5) 0;
        border-radius: 8px;
        background-color: #6aa84f;

        .title {
            color: #fff;
            font-size: px(17);
        }

        .number {
            display: flex;
            align-items: center;
            justify-content: center;
            width: px(105);
            height: px(32);
            color: #fff;
            border-radius: px(18);
            font-size: px(22);
            font-weight: 600;
            background-color: rgba($color: #202630, $alpha: 0.3);
        }
    }
}
