@import './variable.scss';

.USDT {
    display: inline-block;
    background: url('./images/usdt.png') no-repeat;
    background-size: contain;
}

.USDT1 {
    display: inline-block;
    background: url('./images/usdt1.png') no-repeat;
    background-size: contain;
}

.TGB {
    display: inline-block;
    background: url('./images/tgb.png') no-repeat;
    background-size: contain;
}

.ETH {
    display: inline-block;
    background: url('./images/eth.svg') no-repeat;
    background-size: contain;
}

.USDC {
    display: inline-block;
    background: url('./images/usdc.svg') no-repeat;
    background-size: contain;
}


.BNB {
    display: inline-block;
    background: url('./images/bnb.png') no-repeat;
    background-size: contain;
}


.TRX {
    display: inline-block;
    background: url('./images/trx.png') no-repeat;
    background-size: contain;
}


.SOL {
    display: inline-block;
    background: url('./images/sol.png') no-repeat;
    background-size: contain;
}


.BTC {
    display: inline-block;
    background: url('./images/btc.png') no-repeat;
    background-size: contain;
}

.logo-icon {
    display: inline-block;
    background: url('./images/logo.svg') no-repeat;
    background-size: contain;
}

.help {
    display: inline-block;
    background: url('./images/help.svg') no-repeat;
    background-size: contain;
}

.clock {
    display: inline-block;
    background: url('./images/clock.svg') no-repeat;
    background-size: contain;
}

.icon-three-arrow {
    display: inline-block;
    background: url('./images/icon-three-arrow.svg') no-repeat;
    background-size: contain;
}

.trend {
    display: inline-block;
    background: url('./images/trend.svg') no-repeat;
    background-size: contain;
    background-position: center center;
}

.plus-btn {
    display: inline-block;
    background: url('./images/plus-btn.svg') no-repeat;
    background-size: contain;
}

.reduce-btn {
    display: inline-block;
    background: url('./images/reduce-btn.svg') no-repeat;
    background-size: contain;
}

.left-switch-arrow {
    display: inline-block;
    background: url('./images/left-switch-arrow.svg') no-repeat;
    background-size: contain;
}

.right-switch-arrow {
    display: inline-block;
    background: url('./images/right-switch-arrow.svg') no-repeat;
    background-size: contain;
}

.dp {
    display: inline-block;
    background: url('./images/group.svg') no-repeat;
    background-size: contain;
    width: px(11);
    height: px(13);
}

.light-1 {
    display: inline-block;
    background: url('./images/light-1.png') no-repeat;
    background-size: contain;
    background-position: center center;
}

.light-2 {
    display: inline-block;
    background: url('./images/light-2.png') no-repeat;
    background-size: contain;
    background-position: center center;
}

.light-3 {
    display: inline-block;
    background: url('./images/light-3.png') no-repeat;
    background-size: contain;
    background-position: center center;
}

.trade {
    display: inline-block;
    width: px(24);
    height: px(24);
    background: url('./images/trade-normal.svg') no-repeat;
    background-size: contain;

    &.active {
        background-image: url('./images/trade-active.svg');
    }
}

.friends {
    display: inline-block;
    width: px(24);
    height: px(24);
    background: url('./images/friends-normal.svg') no-repeat;
    background-size: contain;

    &.active {
        background-image: url('./images/friends-active.svg');
    }
}

.deposit {
    display: inline-block;
    width: px(24);
    height: px(24);
    background: url('./images/deposit-normal.svg') no-repeat;
    background-size: contain;

    &.active {
        background-image: url('./images/deposit-active.svg');
    }
}

.stock {
    display: inline-block;
    width: px(24);
    height: px(24);
    background: url('./images/deposit-normal.svg') no-repeat;
    background-size: contain;

    &.active {
        background-image: url('./images/deposit-active.svg');
    }
}

.withdraw {
    display: inline-block;
    width: px(24);
    height: px(24);
    background: url('./images/wallet-normal.svg') no-repeat;
    background-size: contain;

    &.active {
        background-image: url('./images/wallet-active.svg');
    }
}

.logout {
    display: inline-block;
    width: px(24);
    height: px(24);
    background: url('./images/logout-normal.svg') no-repeat;
    background-size: contain;
    flex-shrink: 0;

    &.active {
        background-image: url('./images/logout-active.svg');
    }
}

.language {
    display: inline-block;
    width: px(24);
    height: px(24);
    background: url('./images/language.svg') no-repeat;
    background-size: contain;
    flex-shrink: 0;

    &.active {
        background-image: url('./images/language-active.svg');
    }
}

.limit {
    display: inline-block;
    width: px(24);
    height: px(24);
    background: url('./images/gamelimit.svg') no-repeat;
    background-size: contain;
    flex-shrink: 0;

    &.active {
        background-image: url('./images/gamelimit-active.svg');
    }
}

.howtoplay {
    display: inline-block;
    width: px(24);
    height: px(24);
    background: url('./images/howtoplay.svg') no-repeat;
    background-size: contain;
    flex-shrink: 0;

    &.active {
        background-image: url('./images/howtoplay-active.svg');
    }
}

.rule {
    display: inline-block;
    width: px(24);
    height: px(24);
    background: url('./images/gamerules.svg') no-repeat;
    background-size: contain;

    &.active {
        background-image: url('./images/gamerules-active.svg');
    }
}



.gift {
    display: inline-block;
    width: px(143);
    height: px(113);
    background: url('./images/t.png') no-repeat;
    background-size: contain;
}

.invite-empty-icon {
    display: inline-block;
    width: 64px;
    height: 60px;
    background: url('./images/invite-empty-icon.svg') no-repeat center;
    background-size: contain;
}

.checked-icon {
    display: inline-block;
    width: px(19);
    height: px(18);
    background: url('./images/checked-icon.svg') no-repeat;
    background-size: contain;
}

.USDT1 {
    // background: url('./images/coin-small@2x.png');
    background: url('./images/usdt1.png');
    background-size: contain;
}

.USDT {
    // background: url('./images/coin-small@2x.png');
    background: url('./images/usdt.png');
    background-size: contain;
}

.T {
    // background: url('./images/coin-small@2x.png');
    background: url('./images/t.png');
    background-size: contain;
}

// 分享链接
.icon-link-btn {
    width: px(34);
    height: px(34);
    background: url('./images/link-btn@2x.png') no-repeat;
    background-size: contain;
}

// Twitter
.icon-twitter-btn {
    width: px(34);
    height: px(34);
    background: url('./images/twitter-btn@2x.png') no-repeat;
    background-size: contain;
}

// facebook
.icon-facebook-btn {
    width: px(34);
    height: px(34);
    background: url('./images/facebook-btn@2x.png') no-repeat;
    background-size: contain;
}

.icon-phone-btn {
    width: px(34);
    height: px(34);
    background: url('./images/phone-btn@2x.png') no-repeat;
    background-size: contain;
}

.icon-ins-btn {
    width: px(34);
    height: px(34);
    background: url('./images/instagram1.png') no-repeat;
    background-size: contain;
}

.icon-provably-fair-icon-big {
    width: px(60);
    height: px(62);
    background: url('./images/provably-fair-icon-big@2x.png') no-repeat;
    background-size: contain;
}

.icon-database-server {
    width: px(40);
    height: px(38);
    flex-shrink: 0;
    background: url('./images/database-server@2x.png') no-repeat;
    background-size: contain;
}

.icon-machine-person {
    width: px(35);
    height: px(40);
    background: url('./images/machine-person@2x.png') no-repeat;
    background-size: contain;
}

.icon-client-seed {
    width: px(24);
    height: px(17);
    flex-shrink: 0;
    background: url('./images/client-seed-icon@2x.png') no-repeat;
    background-size: contain;
}

.icon-combine {
    width: px(20);
    height: px(20);
    flex-shrink: 0;
    background: url('./images/combine-icon@2x.png') no-repeat;
    background-size: contain;
}


.coin_175_156 {
    width: px(175);
    height: px(156);
    background: url('./images/coin@175_156.png') no-repeat;
    background-size: contain;
}

.USDT_image {
    width: px(100);
    height: px(100);

    background: url('./images/t.png') no-repeat;
    background-size: contain;
}

.shine_175_156 {
    width: px(175);
    height: px(156);
    background: url('./images/shine@175_156.png') no-repeat;
    background-size: contain;
}

.game_play_en-US {
    width: px(298);
    height: px(142);
    background: url('./images/game_play.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-sell_en-US {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-sell_en-US.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-buy_en-US {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-buy_en-US.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-screenshot_en-US {
    width: px(298);
    height: px(80);
    background: url('./images/GameRules/play-screenshot@2x_en-US.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-Invite-screenshot_2x_en-US {
    width: px(300);
    height: px(142);
    background: url('./images/play-Invite-screenshot@2x.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.width-invite-screenshot_2x_en-US {
    width: px(298);
    height: px(260);
    background: url('./images/width-invite-screenshot@2x.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-sell_ar-SA {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-sell_ar-SA.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-buy_ar-SA {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-buy_ar-SA.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-screenshot_ar-SA {
    width: px(298);
    height: px(80);
    background: url('./images/GameRules/play-screenshot@2x_ar-SA.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-Invite-screenshot_2x_ar-SA {
    width: px(298);
    height: px(142);
    background: url('./images/play-Invite-screenshot@2x_ar-SA.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.width-invite-screenshot_2x_ar-SA {
    width: px(298);
    height: px(260);
    background: url('./images/width-invite-screenshot@2x_ar-SA.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-Invite-screenshot_2x_bn-BD {
    width: px(298);
    height: px(142);
    background: url('./images/play-Invite-screenshot@2x_bn-BD.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-sell_bn-BD {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-sell_bn-BD.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-buy_bn-BD {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-buy_bn-BD.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-screenshot_bn-BD {
    width: px(298);
    height: px(80);
    background: url('./images/GameRules/play-screenshot@2x_bn-BD.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.width-invite-screenshot_2x_bn-BD {
    width: px(298);
    height: px(260);
    background: url('./images/width-invite-screenshot@2x_bn-BD.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-sell_es-ES {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-sell_es-ES.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-buy_es-ES {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-buy_es-ES.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-screenshot_es-ES {
    width: px(298);
    height: px(80);
    background: url('./images/GameRules/play-screenshot@2x_es-ES.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-Invite-screenshot_2x_es-ES {
    width: px(298);
    height: px(142);
    background: url('./images/play-Invite-screenshot@2x_es-ES.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.width-invite-screenshot_2x_es-ES {
    width: px(298);
    height: px(260);
    background: url('./images/width-invite-screenshot@2x_es-ES.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-sell_fil-PH {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-sell_fil-PH.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-buy_fil-PH {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-buy_fil-PH.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-screenshot_fil-PH {
    width: px(298);
    height: px(80);
    background: url('./images/GameRules/play-screenshot@2x_fil-PH.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-Invite-screenshot_2x_fil-PH {
    width: px(298);
    height: px(142);
    background: url('./images/play-Invite-screenshot@2x_fil-PH.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.width-invite-screenshot_2x_fil-PH {
    width: px(298);
    height: px(260);
    background: url('./images/width-invite-screenshot@2x_fil-PH.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-sell_fr-FR {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-sell_fr-FR.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-buy_fr-FR {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-buy_fr-FR.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-screenshot_fr-FR {
    width: px(298);
    height: px(80);
    background: url('./images/GameRules/play-screenshot@2x_fr-FR.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-Invite-screenshot_2x_fr-FR {
    width: px(298);
    height: px(142);
    background: url('./images/play-Invite-screenshot@2x_fr-FR.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.width-invite-screenshot_2x_fr-FR {
    width: px(298);
    height: px(260);
    background: url('./images/width-invite-screenshot@2x_fr-FR.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-sell_hi-In {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-sell_hi-IN.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-buy_hi-In {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-buy_hi-IN.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-screenshot_hi-In {
    width: px(298);
    height: px(80);
    background: url('./images/GameRules/play-screenshot@2x_hi-In.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-Invite-screenshot_2x_hi-In {
    width: px(298);
    height: px(142);
    background: url('./images/play-Invite-screenshot@2x_hi-In.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.width-invite-screenshot_2x_hi-In {
    width: px(298);
    height: px(260);
    background: url('./images/width-invite-screenshot@2x_hi-In.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-sell_id-ID {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-sell_id-ID.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-buy_id-ID {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-buy_id-ID.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-screenshot_id-ID {
    width: px(298);
    height: px(80);
    background: url('./images/GameRules/play-screenshot@2x_id-ID.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-Invite-screenshot_2x_id-ID {
    width: px(298);
    height: px(142);
    background: url('./images/play-Invite-screenshot@2x_id-ID.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.width-invite-screenshot_2x_id-ID {
    width: px(298);
    height: px(260);
    background: url('./images/width-invite-screenshot@2x_id-ID.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-sell_ja-JP {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-sell_jp-JP.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-buy_ja-JP {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-buy_ja-JP.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-screenshot_ja-JP {
    width: px(298);
    height: px(80);
    background: url('./images/GameRules/play-screenshot@2x_ja-JP.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-Invite-screenshot_2x_ja-JP {
    width: px(298);
    height: px(142);
    background: url('./images/play-Invite-screenshot@2x_ja-JP.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.width-invite-screenshot_2x_ja-JP {
    width: px(298);
    height: px(260);
    background: url('./images/width-invite-screenshot@2x_ja-JP.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-sell_ko-KR {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-sell_ko-KR.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-buy_ko-KR {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-buy_ko-KR.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-screenshot_ko-KR {
    width: px(298);
    height: px(80);
    background: url('./images/GameRules/play-screenshot@2x_ko-KR.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-Invite-screenshot_2x_ko-KR {
    width: px(298);
    height: px(142);
    background: url('./images/play-Invite-screenshot@2x_ko-KR.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.width-invite-screenshot_2x_ko-KR {
    width: px(298);
    height: px(260);
    background: url('./images/width-invite-screenshot@2x_ko-KR.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-sell_ms-MY {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-sell_ms-MY.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-buy_ms-MY {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-buy_ms-MY.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-screenshot_ms-MY {
    width: px(298);
    height: px(80);
    background: url('./images/GameRules/play-screenshot@2x_ms-MY.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-Invite-screenshot_2x_ms-MY {
    width: px(298);
    height: px(142);
    background: url('./images/play-Invite-screenshot@2x_ms-MY.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.width-invite-screenshot_2x_ms-MY {
    width: px(298);
    height: px(260);
    background: url('./images/width-invite-screenshot@2x_ms-MY.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-sell_my-MM {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-sell_my-MM.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-buy_my-MM {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-buy_my-MM.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-screenshot_my-MM {
    width: px(298);
    height: px(80);
    background: url('./images/GameRules/play-screenshot@2x_my-MM.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-Invite-screenshot_2x_my-MM {
    width: px(298);
    height: px(142);
    background: url('./images/play-Invite-screenshot@2x_my-MM.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.width-invite-screenshot_2x_my-MM {
    width: px(298);
    height: px(260);
    background: url('./images/width-invite-screenshot@2x_my-MM.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-sell_pt-PT {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-sell_pt-PT.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-buy_pt-PT {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-buy_pt-PT.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-screenshot_pt-PT {
    width: px(298);
    height: px(80);
    background: url('./images/GameRules/play-screenshot@2x_pt-PT.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-Invite-screenshot_2x_pt-PT {
    width: px(298);
    height: px(142);
    background: url('./images/play-Invite-screenshot@2x_pt-PT.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.width-invite-screenshot_2x_pt-PT {
    width: px(298);
    height: px(260);
    background: url('./images/width-invite-screenshot@2x_pt-PT.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-sell_ru-RU {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-sell_ru-RU.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-buy_ru-RU {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-buy_ru-RU.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-screenshot_ru-RU {
    width: px(298);
    height: px(80);
    background: url('./images/GameRules/play-screenshot@2x_ru-RU.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-Invite-screenshot_2x_ru-RU {
    width: px(298);
    height: px(142);
    background: url('./images/play-Invite-screenshot@2x_ru-RU.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.width-invite-screenshot_2x_ru-RU {
    width: px(298);
    height: px(260);
    background: url('./images/width-invite-screenshot@2x_ru-RU.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-sell_th-Th {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-sell_th-TH.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-buy_th-Th {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-buy_th-TH.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-screenshot_th-Th {
    width: px(298);
    height: px(80);
    background: url('./images/GameRules/play-screenshot@2x_th-TH.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-Invite-screenshot_2x_th-Th {
    width: px(298);
    height: px(142);
    background: url('./images/play-Invite-screenshot@2x_th-TH.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.width-invite-screenshot_2x_th-Th {
    width: px(298);
    height: px(260);
    background: url('./images/width-invite-screenshot@2x_th-TH.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-sell_vi-VN {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-sell_vi-VN.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-buy_vi-VN {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-buy_vi-VN.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-screenshot_vi-VN {
    width: px(298);
    height: px(80);
    background: url('./images/GameRules/play-screenshot@2x_vi-VN.png') no-repeat;
    background-position: center;
    background-size: contain;
}


.play-Invite-screenshot_2x_vi-VN {
    width: px(298);
    height: px(142);
    background: url('./images/play-Invite-screenshot@2x_vi-VN.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.width-invite-screenshot_2x_vi-VN {
    width: px(298);
    height: px(260);
    background: url('./images/width-invite-screenshot@2x_vi-VN.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-sell_zh-SG {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-sell_zh-SG.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-buy_zh-SG {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-buy_zh-SG.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-screenshot_zh-SG {
    width: px(298);
    height: px(80);
    background: url('./images/GameRules/play-screenshot@2x_zh-SG.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-Invite-screenshot_2x_zh-SG {
    width: px(298);
    height: px(142);
    background: url('./images/play-Invite-screenshot@2x_zh-SG.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.width-invite-screenshot_2x_zh-SG {
    width: px(298);
    height: px(260);
    background: url('./images/width-invite-screenshot@2x_zh-SG.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-sell_zh-TW {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-sell_zh-TW.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-buy_zh-TW {
    width: px(298);
    height: px(142);
    background: url('./images/GameRules/play-buy_zh-TW.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.play-screenshot_zh-TW {
    width: px(298);
    height: px(80);
    background: url('./images/GameRules/play-screenshot@2x_zh-TW.png') no-repeat;
    background-position: center;
    background-size: contain;
}


.play-Invite-screenshot_2x_zh-TW {
    width: px(298);
    height: px(142);
    background: url('./images/play-Invite-screenshot@2x_zh-TW.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.width-invite-screenshot_2x_zh-TW {
    width: px(298);
    height: px(260);
    background: url('./images/width-invite-screenshot@2x_zh-TW.png') no-repeat;
    background-position: center;
    background-size: contain;
}

.earn-money-icon {
    width: px(14);
    height: px(14);
    background: url('./images/earn-money-icon@2x.png') no-repeat;
    background-size: contain;
}

.icon-finger-point {
    width: px(41);
    height: px(52);
    background: url('./images/finger-point@2x.png') no-repeat;
    background-size: contain;
}

.replayer-video-icon {
    width: px(66);
    height: px(18);
    background: url('./images/Replayer-Video.png') no-repeat;
    background-size: contain;
}

.back-icon {
    display: inline-flex;
    width: px(25);
    height: px(25);
    background: url('./images/chevron-back-outline.svg') no-repeat;
    background-size: contain;
}

.replayer-paly-icon {
    display: inline-block;
    background: url('./images/play.svg') no-repeat;
    background-size: contain;
    width: px(20);
    height: px(20);
}

.menu-icon {
    display: inline-block;
    background: url('./images/menu-icon.svg') no-repeat;
    background-size: contain;
    width: 0.85rem;
    height: 0.85rem;
}

.google {
    display: inline-block;
    background: url('./images/google.png') no-repeat;
    background-size: contain;
    width: 1.2rem;
    height: 1.2rem;
}

/* HTML: <div class="loader"></div> */
.loader {
    width: px(18);
    aspect-ratio: 1;
    display: grid;
    border-radius: 50%;
    background:
        linear-gradient(0deg, rgb(232 186 65/50%) 30%, #0000 0 70%, rgb(232 186 65/100%) 0) 50%/8% 100%,
        linear-gradient(90deg, rgb(232 186 65/25%) 30%, #0000 0 70%, rgb(232 186 65/75%) 0) 50%/100% 8%;
    background-repeat: no-repeat;
    animation: l23 1s infinite steps(12);
}

.loader::before,
.loader::after {
    content: "";
    grid-area: 1/1;
    border-radius: 50%;
    background: inherit;
    opacity: 0.915;
    transform: rotate(30deg);
}

.loader::after {
    opacity: 0.83;
    transform: rotate(60deg);
}

.replayer-icon {
    width: px(85);
    height: px(61);
    background: url('./images/replayer-icon.png') no-repeat;
    background-position: center;
    background-size: contain;
}

@keyframes l23 {
    100% {
        transform: rotate(1turn)
    }
}