@import 'src/variable.scss';

.replayer-bottom-info {
    .title-bar {
        display: flex;
        align-items: center;
        padding: 0 px(17);
        height: px(50);
        background-color: #181d26;

        .avatar {
            width: px(34);
            height: px(34);
            border-radius: 50%;
            margin-right: px(10);
            background-color: #000;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain; /* 保持原比例 */
                display: block;
                margin: auto; /* 居中显示 */
                border-radius: 50%;
            }
        }

        .user-name {
            color: #fff;
            font-size: px(13);
        }
    }

    .info-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #202630;

        .title {
            font-size: px(17);
            color: #e8ba41;
            padding-top: px(20);
            padding-bottom: px(17);
            text-align: center;
        }

        .icon {
            width: px(40);
            height: px(40);
        }

        .usdt-number {
            display: flex;
            align-items: center;
            justify-content: center;
            height: px(24);
            margin-top: px(12);
            color: #fff;
            font-size: px(15);
            padding: 0 px(12);
            border-radius: px(11);
            background-color: #13222b;
        }
    }

    .info-content1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #202630;
        // height: percentage($number: 100);

        .title {
            font-size: px(17);
            color: #e8ba41;
            padding-top: px(20);
            padding-bottom: px(17);
            text-align: center;
        }

        .icon {
            width: px(40);
            height: px(40);
        }

        .usdt-number {
            display: flex;
            align-items: center;
            justify-content: center;
            height: px(24);
            margin-top: px(12);
            color: #fff;
            font-size: px(15);
            padding: 0 px(12);
            border-radius: px(11);
            background-color: #13222b;
        }
    }

    .price-info {
        display: flex;
        margin-top: px(12);
        gap: px(20);
        margin-bottom: px(20);
        
    }

    .pw-1{
        width: px(130);
    }

    .pw-2{
        width: px(100);
    }

    .price-info-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        height: px(74);
        padding: px(9) px(10) px(10) px(10);
        border-radius: px(8);
        background-color: #29313d;

        .price-info-item-title {
            color: #fff;
            font-size: px(13);
            font-weight: bold;
        }

        .price-info-item-bottom {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: px(10);

            .number {
                color: #fff;
                font-size: px(13);

                &.sell {
                    color: #e8ba41;
                }
            }

            .rate {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 px(6.5) 0 px(7.5);
                height: px(22);
                font-size: px(13);
                color: #34b4ff;
                font-weight: 600;
                border-radius: px(11);
                background-color: #13222b;
            }
        }
    }

    .trade-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: px(24) px(15) px(30) px(15);
        background-color: #181d26;

        .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: px(40);
            border-radius: px(6);
            background-color: #2ebd85;
            color: #fff;
            font-size: px(17);
            font-weight: bold;
        }
    
        .tips {
            font-size: px(13);
            line-height: px(18);
            margin-top: px(5);
            color: #7a8088;
            text-align: center;
    
            .number {
                color: #e8ba41;
            }
        }
    }

    .success-content {
        display: flex;
        align-items: center;
        padding: px(5) px(9) px(5) px(23);
        color: #fff;
        background-image: linear-gradient(to bottom, #3baf8f, #2ca475);
    
        .svg-icon {
          position: absolute;
          width: px(20);
          height: px(20);
          right: px(9);
          display: flex;
          align-items: center;
        }
    
        .wins-wrap {
          position: absolute;
          right: px(60);
        }
    
        .success-content-text {
          display: inline-block;
          text-align: center;
          max-width: px(150);
          overflow: hidden;
    
          .title {
            font-size: px(12);
            font-weight: 600;
            line-height: px(17);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
    
        .wins-wrap {
          display: flex;
          justify-content: center;
          flex-direction: column;
          width: px(92);
          height: px(40);
          color: #fff;
          background-color: rgba(#202630, 0.3);
          border-radius: px(21);
          padding-top: px(2);
    
          .title {
            font-size: px(12);
            line-height: px(17);
            font-weight: 600;
            text-align: center;
          }
    
          .multiple {
            font-size: px(16);
            font-weight: 600;
            text-align: center;
            line-height: px(22);
          }
        }
      }
    
}